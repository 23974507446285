import React from 'react'
import injectSheet from 'react-jss'
import H2 from './../H2'

const styles = {
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    position: 'relative',
    '& + &': {
      borderTop: [1, 'solid', '#ddd']
    }
  }
}

const Block = ({ classes, children, iconName, title, emailToken }) => (
  <div className={classes.root}>
    <H2 icon={{ name: iconName }}>
      {title}
      {emailToken && (
        <a href={`/sign-docs-V2/${emailToken}`} target="_blank" rel="noopener noreferrer">
          Ссылка на подписание документов
        </a>
      )}
      {/* {emailToken && (
        <a href={`/sign-docs/?token=${emailToken}`} target="_blank" rel="noopener noreferrer">
          Тестовая ссылка на подписание документов
        </a>
      )} */}
    </H2>
    {children}
  </div>
)

export default injectSheet(styles)(Block)
